<template>
  <div>
    <v-container>
      <v-card flat tile>
        <div class="card-title-wrapper" id="sticky-nav">
          <v-card-title class="py-2 grey lighten-4 d-flex">
            <v-btn text class="text-center" @click="toDashboard">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <div class="mx-auto">
              <span class="headline font-weight-light">{{dataType}}</span>
              <p class="text-center mb-0 mt-n1 caption">
                <span v-if="returnDataByType.length > 1">{{ returnDataByType.length }} applications</span>
                <span v-else-if="returnDataByType.length == 1">{{ returnDataByType.length }} application</span>
                <span v-else> no applications</span>
              </p>
            </div>
          </v-card-title>
          <div class="white py-3 d-flex justify-center">
            <v-btn 
              @click="setDataType('Branch applications')" 
              :outlined="dataType !== 'Branch applications'"
              :class="{'white--text': dataType === 'Branch applications'}"
              class="mr-1 text-capitalize" 
              color="secondary"
            >
              <v-icon>mdi-account-group</v-icon>
            </v-btn>
            <v-btn 
              @click="setDataType('Branch completed')" 
              :outlined="dataType !== 'Branch completed'"
              :class="{'white--text': dataType === 'Branch completed'}"
              class="mr-5 text-capitalize" 
              color="secondary"
            >
              <v-icon>mdi-playlist-check</v-icon>
            </v-btn>
            <v-btn 
              @click="setDataType('My inbox')" 
              :outlined="dataType !== 'My inbox'" 
              :class="{'white--text': dataType === 'My inbox'}"
              class="mr-1 text-capitalize" 
              color="primary"
            >
              <v-icon>mdi-account</v-icon>

            </v-btn>
            <v-btn 
              @click="setDataType('My completed')" 
              :outlined="dataType !== 'My completed'" 
              :class="{'white--text': dataType === 'My completed'}"
              class="text-capitalize" 
              color="primary"
            >
              <v-icon>mdi-playlist-check</v-icon>
            </v-btn>
          </div>
        </div>

        <v-card-text v-if="returnDataByType.length" class="px-0 pt-0 mb-0">
          <v-card class="px-1" flat outlined tile v-for="item in returnDataByType" :key="item.OID">
            <v-card-text class="">
              <v-row class="">
                <v-col cols="12" sm="10">
                  <div class="title font-weight-regular">
                    Application made 
                    <span v-if="item.CreatedAt"> on {{item.CreatedAt | filterDate}}</span>
                    <span v-if="item.Name"> by <strong>{{item.Name}}</strong></span>
                    <span v-if="item.Address"> for <strong>{{item.Address}}</strong></span> 
                    ready in agentOS.
                  </div>
                  <div class="mt-5 text-right text-md-left">
                    <div class="font-weight-light">Contact details:</div>
                    <div class="primary--text" v-if="item.Email">{{item.Email}}</div>
                    <div class="primary--text" v-if="item.MobileNumber">{{item.MobileNumber}}</div>
                    <div class="font-weight-light" v-if="!item.Email && !item.MobileNumber">n/a</div>
                  </div>
                </v-col>
                <v-col class="d-flex justify-end justify-sm-center" cols="12" sm="2">
                  <v-btn v-if="dataType === 'Branch applications' || dataType === 'My applications'" 
                  color="success" 
                  outlined
                >
                  <v-icon small left>mdi-playlist-check</v-icon>
                  <span class="custom-transform-class text-none">Mark as completed</span>
                </v-btn>
                </v-col>
              </v-row>


            </v-card-text>
          </v-card>
        </v-card-text>
        <!-- If no data available -->
        <v-card-text class="mt-5" v-else>
          <NoDataMsg>
            Online applications
          </NoDataMsg>
        </v-card-text>
      </v-card>
      
      <!-- <InformationToUser 
        :dialog="showInfoDialog"
        @closeInfoDialog="showInfoDialog = false"
      /> -->
    </v-container>
  </div>
</template>

<script>
import {mapState} from 'vuex'
// import InformationToUser from '@/components/Dialogs/InformationToUser.vue';
import moment from 'moment/src/moment'
import NoDataMsg from '@/components/MessageComponents/Slots/NoDataMsg.vue';

export default {
  name: 'online-applications',
  components: {
    // InformationToUser
    NoDataMsg
  },
  data() {
    return {
      dataType: 'Branch applications',
      showInfoDialog: false,
    }
  },
  methods: {
    toDashboard() {
      this.$router.go(-1)
    },
    setDataType(type) {
      this.dataType = type
    },
    showInformationToUser() {
      this.showInfoDialog = true
    }
  },
  computed: {
    ...mapState({
      branch: state => state.onlineApplications.branch.data,
      branchCompleted: state => state.onlineApplications.branchCompleted.data,
      staff: state => state.onlineApplications.staff.data,
      staffCompleted: state => state.onlineApplications.staffCompleted.data
    }),
    returnDataByType() {
      if(this.dataType === 'My inbox') {return this.staff.Data}
      if(this.dataType === 'My completed') {return this.staffCompleted.Data}
      if(this.dataType === 'Branch completed') {return this.branchCompleted.Data}
      return this.branch.Data
    }
  },
  filters: {
    filterDate(val) {
     return moment(val).format("DD/MM/YYYY")
    }
  }
}
</script>

<style scoped>

</style>